<template>
  <div v-title :data-title="this.$route.query.name">
    <div class="main_box1">
      <div class="main_box1_cont arraws">
        <p>{{id}}</p>
        <span>
          是医学科学中的一门独立学科。以自然科学和社会科学理论为基础，研究、维护、促进、恢复人类健康的护理理论、知识技能及其发展规律的综合性应用科学。
          护理学包含了自然科学，如生物学、物理学、化学、解剖学、生理学等知识。
        </span>
      </div>
    </div>
    <!-- 第二部分 -->
    <div class="main_box2">
      <div class="main_box2_lside">
        <a class="box2_lside_a_active" href="hlxnfzsy_detail.html">护理学虚拟仿真实验系统</a>
        <a href="jjhl_detail.html">急救护理虚拟训练考核系统</a>
        <!-- <a href="#">健康评估虚拟实验系统</a> -->
      </div>
      <div class="main_box2_rside">
        <div class="rside_child_img">
          <img class="img1" alt="" src="">
          <img class="img2" alt="" src="">
        </div>
        <div class="box2_rside_child">
          <p>产品简介</p>
          <span>
            <!-- 解决教学目标不明确、学生理论知识不扎实、联系实际能力差、欠缺沟通与动手能力、缺乏对仪器的规范熟练操作及安全意识，减少院校及用人单位的困扰。 -->
            以人机交互的方式让操作者在操作过程中练就了各种护理技能，同时对操作者的操作加以考核，使其学习巩固所需的基本及重点知识，让操作者更快掌握所需的护理技能。
          </span>
        </div>
        <div class="box2_rside_child">
          <p>用户群体</p>
          <span>
            医学院校内实验教学中心所涉及到的师生，及初入工作的人群，均可使用该开放式平台进行相关的虚拟实验教学和复习。
          </span>
        </div>
        <div class="box2_rside_child">
          <p>平台内容</p>
          <span>
            以三维方式，立体展示护理学相关的实验内容及所需掌握的操作设备、器材。
          </span>
        </div>
        <div class="box2_rside_child">
          <p>优点</p>
          <span>
            用虚拟技术构造虚拟三维场景为用户提供生动的感性认识和现实体验，帮助学生解决学习中实操训练少的问题。
            加速巩固所学知识，掌握操作要领和提高熟练程度。可弥补教学资源不足，并可拓展实验项目，
            使不常见的实验在虚拟仿真实验训练环节中模拟操作并完成。
          </span>
        </div>
      </div>
      <div class="clr"></div>
    </div>
  </div>
</template>
<script>
import Common from '@/components/Common.vue'
export default {
  data () {
    return {
      id: '',
      isShow1: Common.isShow
    }
  },
  created () {
    this.load()
  },
  methods: {
    load: function () {
      Common.isShow = false
      console.log(Common.isShow)
      console.log(this.$route.query.id)
      console.log(123)
    }
  }
}
</script>
<style scoped>
.main_box1 {
  width: 100%;
  height: 400px;
  background: #003064;
}

.main_box1_cont {
  width: 1000px;
  margin: 0 auto;
  padding-top: 0.1px;
}

.main_box1_cont p {
  font-size: 40px;
  color: white;
  margin-top: 100px;
  margin-bottom: 50px;
}

.main_box1_cont span {
  width: 720px;
  font-size: 16px;
  color: white;
  line-height: 25px;
  display: block;
}

/* 第二部分 */
.main_box2 {
  width: 1000px;
  margin: 0 auto;
}

.main_box2_rside {
  width: 655px;
  float: right;
  padding-bottom: 70px;
  cursor: default;
}

.box2_rside_child {
  margin-top: 45px;
}

.box2_rside_child>p {
   font-size: 24px;
   color: #303030;
  margin-bottom: 15px;
}

.box2_rside_child>span {
  font-size: 16px;
  color: #303030;
  line-height: 25px;
  font-family: "SimHei";
}

.rside_child_img {
  height: 174px;
  position: relative;
   margin-top: 48px;
}

.rside_child_img>img {
  width: 309px;
   height: 174px;
  position: absolute;
}

.img1 {
  top: 0px;
  left: 0px;
  z-index: 5;
}

.img2 {
  top: 0px;
  right: 0px;
}
</style>
